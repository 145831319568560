import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import environments from '@app/configs/environments';
import {ProjectListResponse} from "@app/components/my-organizations-sidebar/project-list-view";
import {CreateNewProjectDto} from "@app/models/dtos/projectDto";
import {
    DeleteMemberRequest,
    GetMemberRequest,
    MemberDto,
    MemberInvitationDto,
    ProjectRequest
} from "@app/store/project/types";
import {updateMemberRoleRequest} from "@app/store/organization/types";
import {RemoveInvitationType, SendInvitationType} from "@app/store/invitation/types";
import {GetSettingsResponse} from "@app/components/organization/settings-tab";

export const PROJECT_PATH = 'project';
export const PROJECT_TAG = "PROJECT_TAG"
export const PROJECT_MEMBER_TAG = "PROJECT_MEMBER"


export interface QueryParamsType {
    organizationId: string | Array<string>;
    projectId?: string | Array<string>;
}

export interface LogsType extends QueryParamsType {
    currentDate: String;
}

export interface AuditLogsResponse {
    isLast: number;
    data: Array<AuditTrailResponse>;
}

export interface AddMembersToProjectBodyType extends QueryParamsType {
    usersArray: Array<{ userId: string; role: string; }>
}

export interface AuditTrailResponse {
    id: string;
    userId: string;
    userName: string;
    documentId: string;
    organizationName: string;
    projectName: string;
    documentName: string;
    targetUser: string;
    eventType: string;
    eventDate: number;
    newValue: string;
    currentValue: string;
    severity: string;
    meta: any;
}

export const projectApi = createApi({
    reducerPath: PROJECT_PATH,
    tagTypes: [PROJECT_TAG, PROJECT_MEMBER_TAG],
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.CLIENT_SIDE_ENDPOINT_HOST + '/api',
        credentials: "include",
        prepareHeaders(headers) {
            headers.set('Access-Control-Allow-Origin', environments.CLIENT_SIDE_ENDPOINT_HOST as string);
            headers.set("Content-Type", "application/json");
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getProjectsInOrganization: builder.query<Array<ProjectListResponse>, any>({
            query: ({organizationId, query}) => ({
                url: `/organizations/${organizationId}/projects/mine`,
                method: 'GET',
                params: {
                    "query": query
                },
            }),
            transformResponse: (response: Array<ProjectListResponse>) => {
                // Sort the response by the createdAt field (oldest to newest)
                return response.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
            },
            providesTags: [PROJECT_TAG]
        }),
        updateProjectDetails: builder.mutation<any, ProjectRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/projects/${request.projectId}`,
                method: 'PATCH',
                body: request.requestBody,
            }),
            invalidatesTags: [PROJECT_TAG]
        }),
        getProjectDetails: builder.query<ProjectListResponse, QueryParamsType>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/projects/${request.projectId}`,
                method: 'GET'
            }),
            providesTags: [PROJECT_TAG]
        }),
        deleteProject: builder.mutation<void, any>({
            query: ({organizationId, projectId}) => ({
                url: `organizations/${organizationId}/projects/${projectId}`,
                method: 'DELETE'
            }),
            invalidatesTags: [PROJECT_TAG]
        }),
        getProjectMembers: builder.query<Array<MemberDto | MemberInvitationDto>, any>({
            query: (request: GetMemberRequest) => ({
                url: `/organizations/${request.organizationId}/projects/${request.projectId}/members`,
                method: 'GET',
                params: {
                    query: request.query,
                    sort_by: request.sort_by
                }
            }),
            providesTags: [PROJECT_MEMBER_TAG]
        }),
        deleteProjectMembers: builder.mutation<void, DeleteMemberRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/projects/${request.projectId}/members`,
                method: 'DELETE',
                body: request.userIds
            }),
            invalidatesTags: [PROJECT_MEMBER_TAG]
        }),
        addNewProjectToOrganization: builder.mutation<{ projectId: string; }, CreateNewProjectDto>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects`,
                method: 'POST',
                body: {
                    projectName: requestBody.name,
                    projectDescription: requestBody.description,
                    projectPolicyType: requestBody.policyType
                }
            })
        }),
        getOrganizationMembersNotInTheProject: builder.query<any, QueryParamsType>({
            query: (requestParams) => ({
                url: `/organizations/${requestParams.organizationId}/projects/${requestParams.projectId}/members-not-in-project`,
                method: 'GET'
            }),
            providesTags: [PROJECT_MEMBER_TAG]
        }),
        addMembersToTheProject: builder.mutation<any, AddMembersToProjectBodyType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/members`,
                method: 'POST',
                body: requestBody.usersArray
            })
        }),
        sendInvitationToMemberFromProject: builder.mutation<MemberInvitationDto, SendInvitationType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/invitation/send`,
                method: 'POST',
                body: {
                    email: requestBody.email,
                    role: requestBody.role
                }
            }),
            invalidatesTags: [PROJECT_MEMBER_TAG]
        }),
        removeProjectInvitation: builder.mutation<any, RemoveInvitationType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/invitations/${requestBody.invitationToken}`,
                method: 'DELETE'
            }),
            invalidatesTags: [PROJECT_MEMBER_TAG]
        }),
        updateProjectInvitationRole: builder.mutation<any, updateMemberRoleRequest>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/invitation/${requestBody.identifier}`,
                method: 'PATCH',
                params: {
                    "role": requestBody.role
                }
            }),
            invalidatesTags: [PROJECT_MEMBER_TAG]
        }),
        updateProjectMemberRole: builder.mutation<any, updateMemberRoleRequest>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/members/${requestBody.identifier}`,
                method: 'PATCH',
                params: {
                    "role": requestBody.role
                }
            }),
            invalidatesTags: [PROJECT_MEMBER_TAG]
        }),
        getProjectAuditLogs: builder.query<AuditLogsResponse, LogsType>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/logs?currentDate=${requestBody.currentDate}`,
                method: 'GET'
            })
        }),
        getProjectLevelConfigs:builder.query<Array<GetSettingsResponse>, { organizationId: string, projectId: string }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/configs`,
                method: 'GET'
            }),
            transformResponse: (response: Array<GetSettingsResponse>) => {
                return response.sort((a, b) => {
                    const keyA = a.settingsName.toLowerCase();
                    const keyB = b.settingsName.toLowerCase();
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            },
        }),
        updateProjectLevelConfigs: builder.mutation<any, {
            organizationId: string;
            projectId: string;
            settingsRequestBodyList: Array<{ settingsId: string; settingsValue: string; }>
        }>({
            query: (requestBody) => ({
                url: `/organizations/${requestBody.organizationId}/projects/${requestBody.projectId}/configs`,
                method: 'PATCH',
                body: requestBody.settingsRequestBodyList
            })
        }),
    })
});

export const {
    useGetProjectsInOrganizationQuery,
    useRemoveProjectInvitationMutation,
    useLazyGetProjectsInOrganizationQuery,
    useAddNewProjectToOrganizationMutation,
    useLazyGetOrganizationMembersNotInTheProjectQuery,
    useGetOrganizationMembersNotInTheProjectQuery,
    useUpdateProjectDetailsMutation,
    useDeleteProjectMutation,
    useDeleteProjectMembersMutation,
    useGetProjectMembersQuery,
    useAddMembersToTheProjectMutation,
    useSendInvitationToMemberFromProjectMutation,
    useGetProjectLevelConfigsQuery,
    useUpdateProjectLevelConfigsMutation,
    useUpdateProjectMemberRoleMutation,
    useLazyGetProjectAuditLogsQuery,
    useGetProjectDetailsQuery,
    useUpdateProjectInvitationRoleMutation
}
    = projectApi;
